/**
 * Created by osirvent on 18/11/2015.
 */
angular
    .module('annexaApp')
    .constant('TasksModals', {
        reassignTask: {
            title: 'global.literals.reassignTask',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                        	{
                        		key: 'assignTo',
                        		type: 'annexaRadioCheckboxRow',
                        		className: 'col-sm-12',
                        		defaultValue: 'user',
                        		data: {
                                    informed: true,
                                    row: true,
                                    clear: function ($event, model, key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if ($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                        		templateOptions: {
                        			type: 'radio',
                        			radioClass: 'radio-inline',
                                    optionsAttr: 'bs-options',
                                    required: false,
                                    validate: false,
                                    options:[],
                                    ngOptions:'option[to.valueProp] as option in to.options',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    label: 'global.literals.assign_to'
                        		},
                        		controller: function ($scope, $filter) {
                                    $scope.to.options = [
                                    	{id: 'user', description: $filter('translate')('global.literals.user')}, 
                                    	{id: 'profile', description: $filter('translate')('global.literals.profile')}
                                    ];
                                }
                        	},
                            {
                                key: 'newUser',
                                type: 'annexaLoadUserRow',
                                className: '',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.user',
                                    required: true,
                                    focus: false,
                                    options: [],
                                    loadFunction: function () {}
                                },
                                controller: function ($scope, $rootScope, RestService) {
                                    $scope.options.templateOptions.loadFunction = function(value) {
                                        if(!value && !value.val) {
                                            return [];
                                        }

                                        if(value.val != '*' && value.val.length < 3) {
                                            return [];
                                        }

                                        if(value.val == '*') {
                                            value.val = '';
                                        }

                                        return RestService.loadData('common', 'User', value.val)
                                            .then(function(data) {
                                                var users = [];

                                                if(data.data && data.data.content && data.data.content.length > 0) {
                                                    _.forEach(JSOG.decode(data.data.content), function(val) {
                                                        var name = val.name + ' ' + val.surename1;

                                                        if(val.surename2) {
                                                            name += ' ' + val.surename2;
                                                        }

                                                        users.push({ 'id':  val.id, 'user': val, 'value': name });
                                                    });
                                                }

                                                return users;
                                            }).catch(function() {
                                                return [];
                                            });

                                    }
                                },
                                validators:{
                                    "requiredCouncillor": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = false;
                                            if(modelValue && modelValue.id){
                                                valid = true;
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if (scope.model.assignTo && scope.model.assignTo == 'user') {
                                		return false;
                                	}
                                	return true; 
                                }
                            },
                            {
                                key: 'newProfile',
                                type: 'annexaSelectRow', 
                                className: '',
                                validation: {
                                    show: true
                                },
                                expressionProperties: {
                                    'validation.show': 'formControl.$submitted'
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    focus: true,
                                    required: true,
                                    label: 'global.literals.profile',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: []
                                },
                                controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.options = new SortedArray(GlobalDataFactory.allProfiles,$scope.to.labelProp).sort();
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if (scope.model.assignTo && scope.model.assignTo == 'profile') {
                                		return false;
                                	}
                                	return true; 
                                }
                            },
                            {
                                key: 'comments',
                                type: 'annexaTextAreaRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.reason',
                                    required: true,
                                    focus: false
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        taskNew: {
            title: 'global.literals.new_task_title',
            size: 'modal-lg',
            icon: 'fa fa-check-square-o',
            annexaFormly: {},
            submitModal: function () {}
        },
        taskUpdateComment: {
            title: 'global.literals.update_comment_task_title',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'task.comments',
                                type: 'annexaTextArea',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'text',
                                    label: '',
                                    required: false,
                                    focus: true
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        parentTaskAdd: {
            title: 'global.tasks.literals.linkParentTask',
            size: '',
            icon: 'fa fa-link',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                        	{
                        		key: 'relationType',
                        		type: 'annexaRadioCheckboxRow',
                        		className: 'col-sm-12',
                        		defaultValue: 'subtask',
                        		data: {
                                    informed: true,
                                    row: true,
                                    labelClass: 'label-strong',
                                    clear: function ($event, model, key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if ($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                        		templateOptions: {
                        			type: 'radio',
                        			radioClass: 'radio-inline',
                                    optionsAttr: 'bs-options',
                                    required: true,
                                    validate: false,
                                    options:[],
                                    ngOptions:'option[to.valueProp] as option in to.options',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    label: 'global.tasks.literals.parentRelation'
                        		},
                        		controller: function ($scope, $filter) {
                                    $scope.to.options = [
                                        {id:'subtask', description: $filter("translate")('global.tasks.literals.relationType.subtask')},
                                        {id:'duplicated', description: $filter("translate")('global.tasks.literals.relationType.duplicated')}
                                    ];
                                }
                        	},
                            {
                                key: 'parentTask',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                data: {
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.tasks.literals.task',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    focus: false
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {
            }
        },
        reopenTask: {
            title: 'global.task.reopen',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'comments',
                                type: 'annexaTextAreaRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.reason',
                                    required: true,
                                    focus: false
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        newTaskAddProfileToDocument: {
            title: 'global.tram.literals.add_user_profiles',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'userModifyDocument',
                        type: 'annexaRadioCheckboxRow',
                        className: 'col-sm-12 daughter-label-strong',
                        data: {
                            informed: true,
                            row: true
                        },
                        templateOptions: {
                            type: 'radio',
                            optionsAttr: 'bs-options',
                            required: true,
                            validate: true,
                            options:[],
                            ngOptions:'option[to.valueProp] as option in to.options',
                            valueProp: 'id',
                            labelProp: 'value',
                            label: 'global.tram.literals.add_user_profiles_literal'
                        },
                        controller: function ($scope, $filter) {
                            $scope.to.options = [
                                { id:1, value: $filter("translate")("global.literals.yes") },
                                { id:2, value: $filter("translate")("global.literals.no") }
                            ];
                        }
                    },
                    {
                        key: 'profile',
                        type: 'annexaSelectRow',
                        className: 'col-sm-12',
                        templateOptions: {
                            optionsAttr: 'bs-options',
                            ngOptions: 'option in to.options | filter: $select.search',
                            label: 'global.tram.literals.profile_to_add',
                            valueProp: 'id',
                            labelProp: 'language1',
                            placeholder: '',
                            options: [],
                            required: true,
                            focus: false
                        },
                        controller: ['$scope', 'Language', 'SecFactory', function ($scope, Language, SecFactory) {
                            $scope.to.labelProp = Language.getActiveColumn();
                            $scope.to.options = (($scope.model && $scope.model.modal_body && $scope.model.modal_body.userProfiles)? $scope.model.modal_body.userProfiles : []);
                        }],
                        data: {
                            informed:true,
                            row:true,
                            clear: function($event,model,key, $select) {
                                $event.stopPropagation();
                                model[key] = undefined;
                                if($select) {
                                    $select.selected = undefined;
                                    $select.search = undefined;
                                }
                            }
                        },
                        hideExpression: function($viewValue, $modelValue, scope) {
                            return scope.model.userModifyDocument != 1;
                        }
                    }
                ]
            },
            submitModal: function () {
            }
        }
    });